<template>
  <v-flex>
    <DefaultLoading v-if="loading" :loading="loading" />
    <div v-if="!loading && !error && pdfContent" class="pdf-wrapper">
      <vue-pdf-app :pdf="pdfContent" :file-name="fileName" page-scale="page-width" v-pdf-app-load></vue-pdf-app>
    </div>
  </v-flex>
</template>

<script>
import { SignaturesService } from "../../../services/multiple/signatures-service";
import DefaultLoading from "./DefaultLoading.component";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "auth-user-pdf-viewer",
  components: { VuePdfApp, DefaultLoading },
  props: ["token", "authId", "requestId", "fileName"],
  data: () => ({
    currentPage: 0,
    pageCount: 0,
    loading: false,
    error: false,
    pdfContent: null,
    authenticationCode: null,
    documentAuthToken: null
  }),

  mounted() {
    this.loadDocument();
  },
  created() {
    this.authenticationCode = localStorage.getItem('auth_id') || null;
    let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];


    let userToken = localStorage.getItem("auth_user_token");
   console.log("user token: " + userToken);

    if(userToken){
      documentCheckList.forEach((document) => {
        console.log("documentId: " + document.documentId);
          console.log("this.requestId: " + this.requestId);
        if(document.documentId == this.requestId){
          this.documentAuthToken = localStorage.getItem("auth_user_token");
        }
      });

    }else{
      // Update the document checklist after all status updates are completed
      documentCheckList.forEach((document) => {
        if(document.documentId == this.requestId){
          this.documentAuthToken = document.documentToken;
        }
      });
    }
    

  },
  watch: {
    requestId() {
      this.loadDocument();
    },
  },
  methods: {
    loadDocument() {
      this.error = false;

      console.log("multiple authuserpdfviewer");
      console.log("documentAuthToken " + this.documentAuthToken);
      console.log("token " + this.token); // signaturerequest.token
      let userToken = localStorage.getItem("auth_user_token");
      if(userToken){
        this.token = userToken;
      }


      if (this.requestId) {
        this.loading = true;
        SignaturesService.getCertificateSignatureRequestDocument(
          this.requestId,
          // this.documentAuthToken,
          this.token,
          this.authenticationCode
        )
          .then((response) => {
            if (!this._isDestroyed) {
              this.pdfContent = response.data;
              this.loading = false;
              this.$emit("documentError", false);
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 404) {
              this.error = true;
              this.$emit("documentError", true);
            }
          });
      } else {
        this.error = true;
        this.$emit("documentError", true);
      }
    },
  },
  beforeDestroy() {
    console.log('Destroying: Cleaning up pdfContent and other resources');
    this.pdfContent = null;  // Reset any complex objects

  }
};
</script>
